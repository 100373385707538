.container {
  gap: 14px;
  width: 100%;
  height: 100%;

  > canvas {
    width: inherit !important;
    height: inherit !important;
  }
}
