@use '@content' as *;
@use '@queries' as *;

.pet__item-content {
  @include mainContentStyles;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  align-items: center;
  padding: 12px;
  @media (min-width: 428px) {
    padding: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
    margin: 0px;
    grid-area: 2 / 1 / 3 / 2;
    align-self: center;
  }
}
