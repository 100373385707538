.bottom-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--cst-content-background);
  padding: 12px !important;
  box-shadow: var(--elevation-2);
  z-index: 10;

  @media (min-width: 428px) {
    padding: 14px;
  }

  @media (min-width: 744px) {
    position: static;
    padding: 0;
    box-shadow: none;
    background: none;
  }
}
