@use '@content' as *;
@use '@queries' as *;

.pet__item-content-btns {
  @include mainContentStyles;
  padding: 12px 14px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0;
  box-shadow: none;

  @media (min-width: 428px) {
    padding: 14px;
  }

  @media (min-width: 744px) {
    padding: 0;
    box-shadow: none;
    grid-area: 1 / 1 / 2 / 3;
  }

  .tags {
    display: flex;
    align-items: center;
    gap: 6px;

    @media (min-width: 428px) {
      gap: 8px;
    }

    .tag-info {
      padding: 6.5px 8px;
      border-radius: var(--cornerradius6);

      p {
        font-size: 12px;
        font-weight: 600;
      }

      @media (min-width: 428px) {
        padding: 10px 8px;
        border-radius: var(--cornerradius8);

        p {
          font-size: 14px;
        }
      }
    }
  }
}
